import React from 'react';
import PropTypes from 'prop-types';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import LinePictoTraffic from '../traffic/LinePictoTraffic';
import { hasRealAlerts } from '../../../helpers/TrafficHelper';

/**
 * Need to have an array line.alerts in each line.
 * See @TrafficHelper.mapAlertsToLines()
 */
const LinePictoStatus = (props) => {
  const { line } = props;

  return (
    <div className="m-b-0-25 justify-content-center">
      <LinePictoTraffic line={line} checkAlertCause />
      {
        line.alerts && hasRealAlerts(line.alerts)
          ? (
            <span>
              <span className="visually-hidden">{Translate('traffic.line_title')}</span>
              <svg width="24" height="24" className="icon-status">
                <use xlinkHref="#icon-alert-blur-pink" />
              </svg>
            </span>
          )
          : (
            <svg width="24" height="24" className="icon-status">
              <use xlinkHref="#icon-check-blur" />
            </svg>
          )
      }
    </div>
  );
};

LinePictoStatus.propTypes = {
  line: PropTypes.shape({
    id: PropTypes.string.isRequired,
    picto: PropTypes.string,
    code: PropTypes.string.isRequired,
    bgColor: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    impact: PropTypes.bool,
  }).isRequired,
};

export default LinePictoStatus;
