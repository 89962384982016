import React from 'react';
import PropTypes from 'prop-types';
import { TRANSPORT_MODES } from '../../../config/line';

const TransportModePicto = (props) => {
  const { mode, size } = props;

  switch (mode) {
    case TRANSPORT_MODES.TRAM:
      return (
        <svg width={size} height={size} className="f-text">
          <use xlinkHref="#icon-tram-circle" />
        </svg>
      );
    case TRANSPORT_MODES.BUS:
      return (
        <svg width={size} height={size} className="f-text">
          <use xlinkHref="#icon-bus-circle" />
        </svg>
      );
    case TRANSPORT_MODES.SCODI:
      return (
        <svg width={size} height={size} className="f-text">
          <use xlinkHref="#icon-scodi" />
        </svg>
      );
    case TRANSPORT_MODES.BATCUB:
      return (
        <svg width={size} height={size} className="f-text">
          <use xlinkHref="#icon-batcub-circle" />
        </svg>
      );
    case TRANSPORT_MODES.VCUB:
      return (
        <svg width={size} height={size} className="f-text">
          <use xlinkHref="#icon-vcub-circle" />
        </svg>
      );
    case TRANSPORT_MODES.PARKING:
      return (
        <svg width={size} height={size} className="f-text">
          <use xlinkHref="#icon-pr-circle" />
        </svg>
      );
    case TRANSPORT_MODES.TRAIN_TER:
      return (
        <svg width={size} height={size} className="f-text">
          <use xlinkHref="#icon-sncf-circle" />
        </svg>
      );
    case TRANSPORT_MODES.AUTOCAR:
      return (
        <svg width={size} height={size} className="f-text">
          <use xlinkHref="#icon-autocar-circle" />
        </svg>
      );
    default:
      return (
        <svg width={size} height={size} className="f-text">
          <use xlinkHref="#icon-transport" />
        </svg>
      );
  }
};

TransportModePicto.defaultProps = {
  size: 30,
};

TransportModePicto.propTypes = {
  mode: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  size: PropTypes.number,
};

export default TransportModePicto;
