import React from 'react';
import { useSelector } from 'react-redux';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import PropTypes from 'prop-types';
import routes from '../../../routes';
import Link from '../../elements/Link';
import LinesImpacts from './LinesImpacts';
import TrafficListInProgress from './list/TrafficListInProgress';
import TrafficListFuture from './list/TrafficListFuture';
import SubtitleIcon from '../../elements/title/SubtitleIcon';
import { taggingEventTraffic } from '../../../tagging/homepage/TrafficTaggingActions';
import { HOME_TAGGING_ACTIONS } from '../../../config/home';
import useFavoritesLines from '../../../hooks/favorites/useFavoritesLines';

const TrafficPreview = (props) => {
  const { showTitle } = props;
  const { lastSync, inProgress, future } = useSelector(state => state.traffic);
  const { isMobile } = useSelector(state => state.app);
  const { favoritesLinesWithAlerts, tramsLinesWithAlerts } = useFavoritesLines();

  return (
    <>
      {
        showTitle
          ? <SubtitleIcon text="traffic.title" iconName="icon-alert-blur-pink" size={isMobile ? 50 : 40} />
          : ''
      }
      <div className="tbm-block tbm-block-shadow block-traffic">
        <div className="row align-start justify-space-between border-bottom-light p-b-0 m-b-0-5 p-t-0-25">
          <div className="col-xs-5 title-icon fs-h5 p-l-0-25">
            <svg width="26" height="26" className="f-text">
              <use xlinkHref="#icon-tram-circle" />
            </svg>
            <span className="fs-md-h4">{Translate('traffic.types.tram')}</span>
          </div>
          <div className="col-xs-7 p-l-md-0 p-r-0-25">
            <LinesImpacts lines={tramsLinesWithAlerts} />
          </div>
        </div>

        {
          favoritesLinesWithAlerts.length > 0
            && (
              <div className="row align-start justify-space-between border-bottom-light p-b-0 m-b-0-5 p-t-0-25">
                <div className="col-xs-5 title-icon fs-h5 p-l-0-25">
                  <svg width="26" height="26" className="f-text">
                    <use xlinkHref="#icon-starempty" />
                  </svg>
                  <span className="fs-md-h4">{Translate('traffic.labels.favorites')}</span>
                </div>
                <div className="col-xs-7 p-l-md-0 p-r-0-25">
                  <LinesImpacts lines={favoritesLinesWithAlerts} isFavoritesAlerts />
                </div>
              </div>
            )
        }

        <div className="row border-bottom-light">
          <TrafficListInProgress alerts={inProgress} lastSync={lastSync} />
        </div>

        {
          future.length > 0
            && (
              <div className="row border-bottom-light">
                <TrafficListFuture alerts={future} />
              </div>
            )
        }

        <div className="text-center p-t-0-5 p-md-t">
          <Link route={routes.TRAFFIC.HOME.name}>
            <a className="btn btn-fpink" onClick={() => (taggingEventTraffic(HOME_TAGGING_ACTIONS.TRAFIC_PREVIEW))}>
              {Translate('traffic.more')}
            </a>
          </Link>
        </div>
      </div>
    </>
  );
};

TrafficPreview.propTypes = {
  showTitle: PropTypes.bool,
};

TrafficPreview.defaultProps = {
  showTitle: true,
};

export default TrafficPreview;
