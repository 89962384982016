import PropTypes from 'prop-types';
import React from 'react';
import LinePictoStatus from '../../elements/lines/LinePictoStatus';
import { LINES_TYPES } from '../../../config/line';

const LinesImpacts = (props) => {
  const { lines, isDisruptionsPage, isFavoritesAlerts } = props;

  return (
    <div className="col lines-wrapper p-r-0 p-l-0 p-md-t-0 p-md-b-0-5">
      <div className="display-flex">
        {
          !isDisruptionsPage && isFavoritesAlerts ? (
            lines.filter(line => line.type !== LINES_TYPES.TRAM).map(line => <LinePictoStatus line={line} key={line.id} />)
          ) : (
            lines.map(line => <LinePictoStatus line={line} key={line.id} />)
          )
        }
      </div>
    </div>
  );
};

LinesImpacts.propTypes = {
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      bgColor: PropTypes.string.isRequired,
      textColor: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      impact: PropTypes.bool,
    }),
  ).isRequired,
  isDisruptionsPage: PropTypes.bool,
  isFavoritesAlerts: PropTypes.bool,
};

LinesImpacts.defaultProps = {
  isDisruptionsPage: false,
  isFavoritesAlerts: false,
}

export default LinesImpacts;
