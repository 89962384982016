import PropTypes from 'prop-types';
import React from 'react';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';
import TrafficMessage from '../message/TrafficMessage';

const TrafficListFuture = (props) => {
  const { alerts } = props;

  return (
    <div className="col-xs-12">
      {
        alerts.map((alert, index) => (
          <div key={alert.id}>
            {
              index === 0
                ? (
                  <div className="font-bold text-fpink fs-h4 m-b-0-5 m-t-0-5 m-md-t-0">
                    {Translate('traffic.labels.future')}
                  </div>
                )
                : ''
            }
            <TrafficMessage alert={alert} />
          </div>
        ))
      }
    </div>
  );
};

TrafficListFuture.propTypes = {
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default TrafficListFuture;
