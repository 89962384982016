import PropTypes from 'prop-types';
import React from 'react';
import { t as Translate } from '@vernouf/upgraded-react-redux-i18n';

import TrafficMessage from '../message/TrafficMessage';
import { SCHEDULES_TAGGING_ACTIONS } from '../../../../config/schedule';

const TrafficListInProgress = (props) => {
  const { alerts, lastSync } = props;

  return (
    <div className="col-xs-12">
      <div className="display-flex text-fpink fs-h5 m-t-0-5 m-md-t-0 m-b-0-5">
        <div className="font-bold m-r-0-5 fs-h4">{Translate('traffic.labels.in_progress')}</div>
        <div className="tag tag-up">
          <svg width="10" height="11" className="f-fpink m-r-0-25">
            <use xlinkHref="#icon-refresh" />
          </svg>
          <span>{lastSync}</span>
        </div>
      </div>
      {
        alerts.map(alert => (
          <TrafficMessage alert={alert} key={alert.id} tagging={`${SCHEDULES_TAGGING_ACTIONS.IN_PROGRESS} ${alert.title.substr(0, 88)}`} />
        ))
      }
      {
        alerts.length <= 0
          ? <p className="m-t-0-5 p-t-0-5">{Translate('traffic.working')}</p>
          : null
      }
    </div>
  );
};

TrafficListInProgress.propTypes = {
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  lastSync: PropTypes.string,
};

TrafficListInProgress.defaultProps = {
  lastSync: null,
};

export default TrafficListInProgress;
