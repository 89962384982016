import PropTypes from 'prop-types';
import React from 'react';
import Link from '../../../elements/Link';
import routes from '../../../../routes';
import TrafficPicto from '../../../elements/traffic/TrafficPicto';
import { taggingEventTraffic } from '../../../../tagging/homepage/TrafficTaggingActions';

const TrafficMessage = (props) => {
  const { alert, tagging } = props;

  return (
    <Link route={routes.TRAFFIC.DETAILS.name} params={{ id: alert.id }}>
      <a className="b-link display-flex text-left b-link-arrow bla-pink text-text p-l-0-25 p-md-l-0" onClick={() => (tagging ? taggingEventTraffic(tagging) : null)}>
        <div className={`display-flex align-center align-content-stretch m-r-0-5 impacts-wrapper justify-space-between`}>
          {
            <TrafficPicto alert={alert} />
          }
        </div>

        <span>{alert.title}</span>
      </a>
    </Link>
  );
};

TrafficMessage.propTypes = {
  tagging: PropTypes.string,
  alert: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    impacts: PropTypes.array.isRequired,
  }).isRequired,
};

TrafficMessage.defaultProps = {
  tagging: null,
};

export default TrafficMessage;
