export const AUTOCOMPLETE_TYPES = {
  LINE: 'line',
  STOP_POINT: 'stop_area',
};

export const SCHEDULES_COLORS = [
  'blue', 'green', 'pink', 'orange', 'purple',
];

export const SCHEDULES_TAGGING_ACTIONS = {
  ALL_LINES: 'Toutes les lignes',
  IN_PROGRESS: 'in progress',
};
