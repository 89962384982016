import React from 'react';
import PropTypes from 'prop-types';
import LinePicto from '../lines/LinePicto';
import shapes from '../../../config/shapes';
import { arrayUnique } from '../../../helpers/GenericHelper';
import TransportModePicto from '../lines/TransportModePicto';

const TrafficPicto = (props) => {
  const { alert, size } = props;

  if (alert.event) {
    return (
      <div className="tbm-icon-picto">
        <img src={alert.event.picto} alt={alert.event.name} style={{ height: size }} key={`${alert.id}`} />
      </div>
    );
  }

  if (alert.impacts.length <= 0) {
    return (
      <svg width={size} height={size} className="f-text" key={`${alert.id}`}>
        <use xlinkHref="#icon-transport" />
      </svg>
    );
  }

  if (alert.impacts.length <= 4) {
    return (
      <>
        {
          alert.impacts.map((impact) => {
            if (impact.line) return <LinePicto line={impact.line} key={`${alert.id}_${impact.id}`} />;
            if (impact.vcubStopPoint) {
              return (
                <svg width={size} height={size} className="f-text" key={`${alert.id}_${impact.id}`}>
                  <use xlinkHref="#icon-vcub-circle" />
                </svg>
              );
            }
            if (impact.parkAndRide) {
              return (
                <svg width={size} height={size} className="f-text" key={`${alert.id}_${impact.id}`}>
                  <use xlinkHref="#icon-pr-circle" />
                </svg>
              );
            }
          })
        }
      </>
    );
  }

  if (alert.impacts.length > 4) {
    let transportModes = alert.impacts.map(impact => impact.transportMode);
    transportModes = arrayUnique(transportModes);

    return (
      <>
        {
          transportModes.map(mode => (
              <div className="tbm-icon m-b-0" key={`${alert.id}_mode_${mode}`}>
                <TransportModePicto mode={mode} />
              </div>
          ))
        }
      </>
    );
  }

  return <TransportModePicto mode="default" />;
};

TrafficPicto.defaultProps = {
  size: 30,
};

TrafficPicto.propTypes = {
  alert: PropTypes.shape(shapes.alertFromLine).isRequired,
  size: PropTypes.number,
};

export default TrafficPicto;
