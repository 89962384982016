import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFavoritesLines } from '../../actions/TrafficInfoActions';
import { mapAlertsToLines } from '../../helpers/TrafficHelper';
import useFavoriteInitialization from './useFavoriteInitialization';
import { LINES_TYPES } from '../../config/line';

const useFavoritesLines = () => {
  const [favoritesLinesWithAlerts, setFavoritesLinesWithAlerts] = useState([]);
  const [tramsLinesWithAlerts, setTramsLinesWithAlerts] = useState([]);
  const [favoritesLines, setFavoritesLines] = useState([]);
  const [favoritesAlertsBus, setFavoritesAlertsBus] = useState([]);

  const { trams, favorites } = useSelector(state => state.traffic);
  const { lines, trams: tramLines } = useSelector(state => state.lines);
  const { alert_lines: alertLines } = useSelector(state => state.favorites);
  const dispatch = useDispatch();

  useFavoriteInitialization();

  useEffect(() => {
    const favoritesIds = alertLines?.map(line => line.lineId);
    dispatch(fetchFavoritesLines(favoritesIds));

    const favoritesLinesFiltered = lines?.filter(line => favoritesIds.includes(line.id));

    setFavoritesLines(favoritesLinesFiltered);
  }, [alertLines]);

  useEffect(() => {
    if (favoritesLines.length > 0) {
      const linesWithAlerts = mapAlertsToLines(favoritesLines, favorites);
      setFavoritesLinesWithAlerts(linesWithAlerts);
      setFavoritesAlertsBus(linesWithAlerts?.filter(line => line?.type === LINES_TYPES.BUS));
    }
  }, [favoritesLines, favorites]);

  useEffect(() => {
    if (tramLines.length > 0) {
      setTramsLinesWithAlerts(mapAlertsToLines(tramLines, trams));
    }
  }, [tramLines, trams]);

  return {
    favoritesLinesWithAlerts,
    tramsLinesWithAlerts,
    favoritesAlertsBus,
  };
};

export default useFavoritesLines;
